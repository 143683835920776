.container-need-help {
  position: absolute;
  bottom: 20px;
  right: 20px;
  max-width: 66px;
  max-height: 66px;
  z-index: 99;
  .wrapper-need-help {
    position: relative;
    height: 100%;
    width: 100%;
    .need-help-img {
      height: 66px;
      width: 66px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #3cbb96;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    .need-help-box {
      width: 300px;
      height: 0px;
      background: #fff;
      border-radius: 10px;
      position: absolute;
      top: -190px;
      right: 0;
      opacity: 0;
      z-index: 0;
      overflow: hidden;
      transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
      h2 {
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        margin: 0;
        padding: 0;
        text-align: center;
        padding: 10px 0;
        background: #3cbb96;
        padding: 10px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #000;
        margin: 0;
        padding: 0;
        text-align: center;
        padding: 10px 0;
        background: #fff;
        padding: 5px;
      }
    }
    .need-help-box-active {
      top: -210px;
      opacity: 1;
      z-index: 2;
      height: 200px;
      //add shadow
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out,
        height 0 ease-in-out 0.3;
    }
  }
}
