// marginLeft: '5px',
// padding: '5px',
// border: '1px solid #ccc',
// borderRadius: '100%',
// width: '30px',
// height: '30px',
// fontSize: '12px',
// display: 'flex',
// alignItems: 'center',
// justifyContent: 'center',
// position: 'relative',

.more-info {
  margin: 5px;
  padding: 5px;
  border: 1px solid #3ab795;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #3ab795;
  cursor: pointer;

  &-tool-tip {
    position: absolute;
    top: 110%;
    width: 200px;
    background: #fff;
    border: 1px solid #3ab795;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 100;
    display: none;

    h6 {
      color: #3ab795;
      font-weight: 400;
    }
    p {
      color: #000;
    }
  }

  &:hover {
    .more-info-tool-tip {
      display: block;
    }
  }
}
