/*!

 =========================================================
 * Material Dashboard React - v1.9.0 based on Material Dashboard - v1.2.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
 input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.closex{
  position: absolute;
    float: right;
    top: 0;
    right: 10px;
    color:#fff!important;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.ct-grid {
  stroke: rgba(255, 255, 255, 0.2);
  stroke-width: 1px;
  stroke-dasharray: 2px;
}
.d-field {
  font-size: 14px;
}

.txtalign {
  text-align: center;
}

.text-right {
  text-align: right;
}

.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: rgba(255, 255, 255, 0.8);
}

.ct-label.ct-horizontal.ct-end {
  -webkit-box-align: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-box-pack: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: flex-start;
  justify-content: flex-start;
  text-align: left;
  text-anchor: start;
}

.ct-label {
  color: rgba(255, 255, 255, 0.7);
}

.ct-chart-line .ct-label,
.ct-chart-bar .ct-label {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.ct-label {
  fill: rgba(0, 0, 0, 0.4);
  line-height: 1;
}

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
  margin: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

small {
  font-size: 80%;
}

h1 {
  font-size: 3em;
  line-height: 1.15em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.825em;
  line-height: 1.4em;
  margin: 20px 0 10px;
}

h4 {
  font-size: 1.3em;
  line-height: 1.4em;
}

h5 {
  font-size: 1.25em;
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6 {
  font-size: 1em;
  text-transform: uppercase;
  font-weight: 500;
}

body {
  background-color: #eeeeee;
  color: #3c4858;
}

blockquote p {
  font-style: italic;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}

a {
  color: #3ab795;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #199272;
  text-decoration: none;
}

legend {
  border-bottom: 0;
}

* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: 0;
}

a:focus,
a:active,
button:active,
button:focus,
button:hover,
button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
select::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  outline: 0 !important;
}

legend {
  margin-bottom: 20px;
  font-size: 21px;
}

output {
  padding-top: 8px;
  font-size: 14px;
  line-height: 1.42857;
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: #aaaaaa;
  font-weight: 400;
}

footer {
  padding: 15px 0;
}

footer ul {
  margin-bottom: 0;
  padding: 0;
  list-style: none;
}

footer ul li {
  display: inline-block;
}

footer ul li a {
  color: inherit;
  padding: 15px;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  display: block;
}

footer ul li a:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  body,
  html {
    position: relative;
    overflow-x: hidden;
  }

  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 0;
    top: 0;
    left: auto;
    right: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
  }
}

.fixed-plugin {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
  position: fixed;
  top: 180px;
  right: 0;
  width: 64px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1031;
  border-radius: 8px 0 0 8px;
  text-align: center;
  top: 120px;
  /* .badge-primary-background-color: #9c27b0; */
}

.fixed-plugin .SocialMediaShareButton,
.fixed-plugin .github-btn {
  display: inline-block;
}

.fixed-plugin li > a,
.fixed-plugin .badge {
  transition: all 0.34s;
  -webkit-transition: all 0.34s;
  -moz-transition: all 0.34s;
  text-decoration: none;
}

.fixed-plugin .fa-cog {
  color: #ffffff;
  padding: 10px;
  border-radius: 0 0 6px 6px;
  width: auto;
}

.fixed-plugin .dropdown-menu {
  right: 80px;
  left: auto;
  width: 290px;
  border-radius: 0.1875rem;
  padding: 0 10px;
  position: absolute;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: #fff;
  border-radius: 3px;
}

.fixed-plugin .fa-circle-thin {
  color: #ffffff;
}

.fixed-plugin .active .fa-circle-thin {
  color: #00bbff;
}

.fixed-plugin .dropdown-menu > .active > a,
.fixed-plugin .dropdown-menu > .active > a:hover,
.fixed-plugin .dropdown-menu > .active > a:focus {
  color: #777777;
  text-align: center;
}

select#facility {
  height: 35px;
}

select#status {
  height: 35px;
}

input#dt {
  height: 35px;
}

button#dt {
  height: 35px;
}

.jss11 {
  width: 100% !important;
}

.fixed-plugin img {
  border-radius: 0;
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.makeStyles-logoImage-11 {
  width: 100% !important;
}

.fixed-plugin .dropdown-menu li > a:hover,
.fixed-plugin .dropdown-menu li > a:focus {
  box-shadow: none;
}

.fixed-plugin .badge {
  border: 3px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 23px;
  margin-right: 5px;
  position: relative;
  width: 23px;
  background-color: rgba(30, 30, 30, 0.97);
}

.fixed-plugin .badge.active,
.fixed-plugin .badge:hover {
  border-color: #00bbff;
}

.fixed-plugin .badge-purple {
  background-color: #9c27b0;
}

.fixed-plugin .badge-blue {
  background-color: #00bcd4;
}

.fixed-plugin .badge-green {
  background-color: #4caf50;
}

.fixed-plugin .badge-orange {
  background-color: #ff9800;
}

.fixed-plugin .badge-red {
  background-color: #f44336;
}

.fixed-plugin h5 {
  font-size: 14px;
  margin: 10px;
}

.fixed-plugin .dropdown-menu li {
  display: block;
  padding: 4px 0px;
  width: 25%;
  float: left;
}

.fixed-plugin li.adjustments-line,
.fixed-plugin li.header-title,
.fixed-plugin li.button-container {
  width: 100%;
  height: 50px;
  min-height: inherit;
  padding: 0px;
  text-align: center;
}

.fixed-plugin li.adjustments-line p {
  margin: 0;
}

.fixed-plugin li.adjustments-line div,
.fixed-plugin li.header-title div,
.fixed-plugin li.button-container div {
  margin-bottom: 5px;
}

.fixed-plugin li.header-title {
  height: 30px;
  line-height: 25px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.fixed-plugin .adjustments-line p {
  float: none;
  display: inline-block;
  margin-bottom: 0;
  font-size: 1em;
  color: #3c4858;
}

.fixed-plugin .adjustments-line a {
  color: transparent;
}

.fixed-plugin .adjustments-line a .badge-colors {
  position: relative;
  top: -2px;
}

.fixed-plugin .adjustments-line a a:hover,
.fixed-plugin .adjustments-line a a:focus {
  color: transparent;
}

.fixed-plugin .adjustments-line .dropdown-menu > li.adjustments-line > a {
  padding-right: 0;
  padding-left: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0;
}

.fixed-plugin .dropdown-menu > li > a.img-holder {
  font-size: 16px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  border: 3px solid #fff;
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
  cursor: pointer;
  display: block;
  max-height: 100px;
  overflow: hidden;
  padding: 0;
}

th,
td {
  text-align: left;
  padding: 4px;
}

.fixed-plugin .dropdown-menu > li > a.img-holder img {
  margin-top: auto;
}

.fixed-plugin .dropdown-menu > li:hover > a.img-holder,
.fixed-plugin .dropdown-menu > li:focus > a.img-holder {
  border-color: rgba(0, 187, 255, 0.53);
}

.fixed-plugin .dropdown-menu > .active > a.img-holder,
.fixed-plugin .dropdown-menu > .active > a.img-holder {
  border-color: #00bbff;
  background-color: #ffffff;
}

.fixed-plugin .dropdown .dropdown-menu {
  -webkit-transform: translateY(-15%);
  -moz-transform: translateY(-15%);
  -o-transform: translateY(-15%);
  -ms-transform: translateY(-15%);
  transform: translateY(-15%);
  top: 27px;
  opacity: 0;
  transform-origin: 0 0;
  display: none;
}

.fixed-plugin .dropdown .dropdown-menu:before {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid rgba(0, 0, 0, 0.2);
  border-top: 0.4em solid transparent;
  right: -16px;
  top: 46px;
}

.fixed-plugin .dropdown .dropdown-menu:after {
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid #ffffff;
  border-top: 0.4em solid transparent;
  right: -16px;
}

.fixed-plugin .dropdown .dropdown-menu:before,
.fixed-plugin .dropdown .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 46px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}

.fixed-plugin .dropdown.show .dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(-13%);
  -moz-transform: translateY(-13%);
  -o-transform: translateY(-13%);
  -ms-transform: translateY(-13%);
  transform: translateY(-13%);
  transform-origin: 0 0;
}

.fixed-plugin.rtl-fixed-plugin {
  right: auto;
  left: 0px;
  border-radius: 0 8px 8px 0;
}

.fixed-plugin.rtl-fixed-plugin .dropdown-menu {
  right: auto;
  left: 80px;
}

* {
  letter-spacing: normal !important;
}

/* Custom table header */

.link {
  display: flex;
  align-items: center;
}

.add-icon {
  display: flex;
  flex: 0 0 auto;
}

.custom-search-box {
  position: relative;
  max-width: 470px;
  display: inline-block;
  width: 100%;
}

.custom-search-box input {
  height: 40px;
  width: 100%;
  padding: 0 38px 0 15px;
  border: 1px solid #b7b7b7;
}

.search-icon {
  display: flex;
  position: absolute;
  top: 9px;
  right: 7px;
}

.t-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 -10px;
  flex-wrap: wrap;
  padding-bottom: 25px;
}

.t-header-left {
  flex: 0 0 auto;
}

.t-header-right {
  flex: 1 1 auto;
  text-align: right;
}

.t-header-left,
.t-header-right {
  padding: 0 10px;
}

/* Sidebar color */
.MuiDrawer-docked .MuiPaper-root > div {
  background: #ffffff;
}

.MuiListItem-button .MuiListItemText-root,
.MuiListItem-button svg {
  color: #111111;
}

.active .MuiListItem-button .MuiListItemText-root,
.active .MuiListItem-button svg {
  color: #ffffff;
}

button.btn-cancel {
  background: #dddddd;
  color: #000000;
  box-shadow: 0 2px 2px 0 rgba(153, 153, 153, 0.14), 0 3px 1px -2px rgba(153, 153, 153, 0.2),
    0 1px 5px 0 rgba(153, 153, 153, 0.12);
}

button.btn-cancel:hover {
  background: #000000;
  color: #ffffff;
}

.m-t-15 {
  margin-top: 15px !important;
}

.full-width-elem {
  width: 100%;
}

.custom-select {
  margin: 27px 0 0 0;
  position: relative;
  padding-bottom: 10px;
  vertical-align: unset;
}

.custom-select label {
  color: #aaaaaa !important;
  font-size: 14px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.12857;
}

.custom-select .MuiFormControl-root {
  width: 100%;
}

.custom-select .MuiInput-underline:before {
  border-color: #d2d2d2 !important;
  border-width: 1px !important;
}

.custom-select .MuiInput-underline:after {
  border-color: #3ab795;
}

.custom-upload-btn {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.custom-upload-btn .MuiFormControl-root {
  display: none;
}

.makeStyles-card-95 {
  padding: 10px !important;
}

.custom-upload-btn > span {
  display: inline-block;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 0.3125rem 1px;
  padding: 12px 30px;
  position: relative;
  font-size: 12px;
  min-width: auto;
  min-height: auto;
  text-align: center;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 400;
  line-height: 1.42857143;
  white-space: nowrap;
  will-change: box-shadow, transform;
  touch-action: manipulation;
  border-radius: 3px;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 0 2px 2px 0 rgba(187, 187, 187, 0.14), 0 3px 1px -2px rgba(82, 82, 82, 0.2),
    0 1px 5px 0 rgba(70, 70, 70, 0.12);
  background-color: #999999;
}

.custom-upload-btn > span:hover {
  background-color: #3ab795;
  box-shadow: 0 2px 2px 0 rgba(58, 183, 149, 0.14), 0 3px 1px -2px rgba(58, 183, 149, 0.2),
    0 1px 5px 0 rgba(58, 183, 149, 0.12);
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-t-27 {
  margin-top: 27px;
}

/*.v-actions .v-action{
margin: 0 0 0 84px!important;
}*/

.preview-image {
  /*border: 1px solid #dddddd;
  margin-bottom: 30px;
  max-height: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;*/
  border: 1px solid #dddddd;
  margin-bottom: 30px;
  max-height: 120px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*.loader {
  text-align: center;  
  margin-top: 96px;
  z-index: 999999;
  display: none;
}*/
.loader {
  text-align: center;
  /* margin-left: 300px; */
  /* margin-top: 96px; */
  z-index: 999999;
  margin: 0 0 0 120px;
  position: absolute;
  /* display: none; */
  width: 70px;
}
.loaders {
  z-index: 999999;
  display: flex;
  width: 100px;
  float: left;
  position: fixed;
  margin-left: 400px;
}

.makeStyles-cardHeader-92 {
  z-index: 0 !important;
}

.t-header-left {
  margin-top: 14px;
}

.dashboardCard {
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 14%) !important;
  padding: 0 1.8rem 1.8rem 1.8rem !important;
  position: relative;
}
.table_card_height {
  height: auto !important;
}

.dashboardHead {
  padding: 1.2rem 1.8rem !important;
  margin-left: -1.8rem;
  margin-right: -1.8rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 15px;
  border-bottom: 1px solid #ddd;
}

.dashboardRadio {
  font-weight: 600;
  display: flex;
  margin-top: 1rem;
}

.dashboardRadio > div {
  display: inline-flex;
  line-height: 16px;
  margin-right: 5px;
}

.dashboardRadio input {
  margin-right: 0.5rem;
  margin-top: 0.2rem;
}

.dashboardBtn {
  color: #3ab795;
  border: 2px solid #3ab795;
  font-weight: 600;
  display: inline-block;
  padding: 8px 14px;
  border-radius: 6px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  min-width: 160px;
  width: fit-content;
  margin: 2rem auto -3rem auto;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}

.ct-series-a .ct-bar,
.ct-series-a .ct-line,
.ct-series-a .ct-point,
.ct-series-a .ct-slice-donut {
  stroke: #0cc162;
}
.ct-series-b .ct-bar,
.ct-series-b .ct-line,
.ct-series-b .ct-point,
.ct-series-b .ct-slice-donut {
  stroke: #201d1d;
}
.ct-chart {
  margin: auto;
  width: 300px;
  height: 300px;
}
.ct-label.ct-vertical.ct-start {
  color: black;
}
.ct-chart-bar .ct-label.ct-horizontal.ct-end {
  color: black;
}

.verticalNaming {
  text-align: center;
  font-weight: 600;
  transform: rotate(-90deg);
  position: absolute;
  left: -1.8rem;
  top: 14rem;
}
.foo {
  background-color: red;
}
.foo {
  background-color: green;
}

.verticalNamingRefund {
  text-align: center;
  font-weight: 600;
  transform: rotate(-90deg);
  position: absolute;
  left: -2.6rem;
  top: 14rem;
}

.horizontalNaming {
  text-align: center;
  font-weight: 600;
}

.headName {
  font-size: 26px;
  margin: 0;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  line-height: 43px;
  color: #333;
}

.paraName {
  font-size: 16px;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  line-height: 43px;
  text-align: right;
}

.filterCard {
  margin-top: 0 !important;
  padding: 0 0.5rem !important;
}

.filterCard .t-header {
  padding-bottom: 0 !important;
}

.filterCard .t-header-left {
  margin-top: 0 !important;
  font-weight: 600;
}

.filterCard .t-header-left button {
  padding: 9px 30px !important;
}
.filterCard .full-width-elem {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid #b7b7b7;
}
.filterCard .full-width-elem-date-picker {
  padding: 2px 8px;
  border-radius: 5px;
  border: 1px solid #b7b7b7;
  /* width: 7.5rem; */
}
.paymentTable thead tr th {
  font-size: 15px;
}
.subHeading {
  font-size: 22px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #111;
}
.uploadCard {
  justify-content: center !important;
  margin-top: 2rem;
}
.uploadCard img {
  width: 30px;
}
.uploadCard p {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}
.uploadCard .t-header-left {
  border: 1px solid #ababab;
  border-radius: 10px;
  padding: 3rem 3rem;
  text-align: center;
  max-width: 225px;
}
.uploadTxt {
  text-align: center;
  margin-top: 0rem;
}
.uploadTxt .btn {
  color: #fff;
  border: 2px solid #3ab795;
  font-weight: 600;
  display: inline-block;
  padding: 8px 14px;
  border-radius: 6px;
  text-align: center;
  font-size: 15px;
  cursor: pointer;
  min-width: 160px;
  width: fit-content;
  margin: 0;
  background-color: #3ab795;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}
.uploadTxt p {
  color: #3ab795;
  font-weight: 600;
  font-size: 16px;
  margin-top: 1rem;
}

.facilityDashboard .MuiGrid-grid-md-8 {
  max-width: 63%;
  margin-left: 1.2%;
}

.dash_card .MuiGrid-grid-md-8 {
  max-width: 64%;
}
.facilityDashboard .filterCard .t-header-left button {
  padding: 0px !important;
  margin: 0;
}
.facilityDashboard .filterCard .search_t_head {
  padding-right: 0;
}
.facilityDashboard .filterCard {
  position: relative;
}
.facilityDashboard .filterCard .filter_txt {
  position: absolute;
  top: 0.9rem;
  left: -5rem;
  text-transform: uppercase;
  font-weight: 600;
}

.dash_card .filterCard .t-header-left button {
  padding: 0px !important;
  margin: 0;
}
.dash_card .filterCard .search_t_head {
  padding-right: 0;
}
.dash_card .filterCard {
  position: relative;
}
.dash_card .filterCard .filter_txt {
  position: absolute;
  top: 0.9rem;
  left: -5rem;
  text-transform: uppercase;
  font-weight: 600;
}
.dash_card .MuiGrid-grid-md-12 {
  max-width: 97.5%;
}
.search_ico {
  width: 24px;
}
.makeStyles-appBar-44 {
  background-color: #fff !important;
  border-bottom: 1px solid #ddd !important;
  box-shadow: 0px 2px 4px -1px rgb(210 210 210 / 20%), 0px 4px 5px 0px rgb(243 243 243 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}
.MuiPaper-elevation4 {
  background-color: #fff !important;
  border-bottom: 1px solid #ddd !important;
  box-shadow: 0px 2px 4px -1px rgb(210 210 210 / 20%), 0px 4px 5px 0px rgb(243 243 243 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.chart_heads {
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 0 -30px;
}
.user_prfile {
  display: flex;
  align-items: center;
  justify-items: center;
  margin-left: -8rem;
  width: auto;
}
.user_prfile img {
  width: 30px;
  margin-right: 0.5rem;
}
.user_prfile p {
  font-size: 13px;
  font-weight: 600;
  margin: 0;
  width: auto;
  /* margin-right: 0.5rem; */
  /* overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
}
.user_prfile img.arrow {
  width: 8px;
  margin-right: 0;
  margin-left: 0.5rem;
}
.search-facility {
  justify-content: flex-start !important;

}
