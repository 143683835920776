.choose-payment-client {
  a {
    color: #000;
  }
  min-height: 100vh;
  min-width: 100vw;
  overflow-x: hidden;
  background-color: #fff;
  .header {
    display: flex;
    padding-top: 3vh;
    padding-left: 3vw;
    height: 10vh;
    .logo-client {
      img {
        height: 100%;
      }
    }
  }
  main {
    z-index: 2;

    display: flex;
    position: relative;

    .left {
      transition: all 0.5s ease-in-out;
      width: 100vw;
      &-selected {
        @media (max-width: 768px) {
          width: 0px;
        }
      }
      .options {
        min-width: 100vw;
        padding-left: 3vw;
        padding-top: 1vh;
        padding-right: 3vw;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 3vw;
        //add delay to grid-template-columns to make it smooth
        transition: grid-template-columns 0.5s ease-in-out,
          min-width 0.5s ease-in-out, width 0.5s ease-in-out;
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 70px;
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
        .option {
          display: flex;
          flex-direction: column;
          gap: 2px;
          align-items: center;
          background: #ededed;
          padding: 20px;
          border-radius: 10px;
          min-width: 100%;
          .sub-text {
            font-size: 12px;
            font-weight: 400;
            color: #000;
            text-align: left;
            align-self: flex-start;
            overflow-wrap: break-word;
            word-break: break-all;
            hyphens: manual;

            a {
              color: #39b794;
              font-weight: 600;
              target-new: tab;
            }
          }
        }
        // .disabled {
        //   opacity: 0.5;
        //   cursor: not-allowed;
        // }
      }
      .selected {
        min-width: 65vw;
        grid-template-columns: 1fr 1fr 1fr;
        font-weight: 600;
        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }

      .select-button {
        background-color: #fff;
        position: absolute;
        bottom: 20px;
        left: 20px;
      }
    }
    .claim-ui {
      min-width: 35vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #39b794;
      margin-top: -10vh;
      height: 100vh;
      z-index: 1;
      position: relative;
      @media (max-width: 768px) {
        min-width: 100vw;
      }
      .back-button {
        display: none;
        @media (max-width: 768px) {
          display: block;
          position: absolute;
          width: 60px;
          height: 60px;
          background-color: #fff;
          color: #39b794;
          border-radius: 50%;
          display: flex;
          align-items: center;
          left: 0;
          top: 0;
          margin: 2vw;
          justify-content: center;
          z-index: 3;
        }
      }
      .top {
        display: flex;
        flex-direction: column;
        gap: 0.8vh;
        align-items: center;
        @media (max-width: 768px) {
          padding-top: 5vh;
        }
        height: 45vh;
        .image {
          height: 90%;
          img {
            max-height: 100%;
            width: 100%;
            padding: 3vw;
          }
        }
        .name {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          padding: 0 20px;
        }
      }
      .bottom {
        background-color: #fff;
        margin: 10px 10px 0px 10px;
        border-radius: 10px 10px 0px 0px;
        height: 55vh;
        position: relative;
        .method-info {
          padding: 1.5vw;

          overflow: auto;
          display: flex;
          flex-direction: column;
          max-height: 45vh;
          gap: 10px;
          .sub-title {
            font-size: 12px;
            color: #000;
            a {
              color: #39b794;
              font-weight: 600;
              target-new: tab;
            }
            p {
              display: inline;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: 0px;
          min-width: 100%;
          background-color: #ffffffd6;
          padding: 10px;
          //glass effect
          backdrop-filter: blur(2px);
        }
      }
    }
  }
}
