@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import 'colors';
@import 'mixins';

/** Global Styles
================================================**/

* {
  margin: 0;
  padding: 0;
}

a {
  color: $textGrayColor;
  text-decoration: none !important;
  cursor: pointer;
}

a:hover {
  color: $textHoverColor;
}

a:focus,
button:focus,
.btn:focus,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
}

img {
  max-width: 100%;
}

button,
input,
label,
select,
textarea {
  resize: none;
}
.MuiButton-contained {
  box-shadow: none !important;
}
.MuiButton-root {
  text-transform: none !important;
}
*,
:after,
:before,
html,
body {
  @include box-sizing(border-box);
  font-family: 'Open Sans', sans-serif;
}

.dashboardCard {
  height: 500px;
}
a.dashboardBtn {
  bottom: 30px;
  left: 26%;
  position: absolute;
}

::-moz-selection {
  background-color: $primaryDarkColor;
  color: $primaryWhiteColor;
}

::-webkit-selection {
  background-color: $primaryDarkColor;
  color: $primaryWhiteColor;
}

::selection {
  background-color: $primaryDarkColor;
  color: $primaryWhiteColor;
}

p {
  margin: 0;
}

ul {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}
.p-t-5 {
  padding-top: 5px !important;
}
.p-b-5 {
  padding-bottom: 5px !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-t-15 {
  padding-top: 15px !important;
}
.p-b-15 {
  padding-bottom: 15px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-15 {
  margin-top: 15px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}

.form-row {
  margin: 0 -10px;
  @include flexbox;
  @include flex-wrap(wrap);
}
.form-label {
  letter-spacing: 0px;
  color: #606060;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 22px;
  display: block;
  font-weight: 400;
}
.w100,
.w50,
.w33,
.w66 {
  padding: 0 10px;
}

.w100 {
  @include flex(0, 0, 100%);
}

.w50 {
  @include flex(0, 0, 50%);
}

.w33 {
  @include flex(0, 0, 33.33%);
}

.w66 {
  @include flex(0, 0, 66.66%);
}

.form-field {
  margin-bottom: 20px;
}

.d-flex {
  @include flexbox;
}

html,
body {
  background-color: $primaryBgColor;
}

.container {
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding: 0 15px;
}

.welcome-banner {
  @include flexbox;
  @include flex-direction(column);
  @include flex-wrap(wrap);
  min-height: 100vh;
  background-image: url(../img/banner01.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center left;
}
header {
  &.s-header {
    @include flex(0, 0, auto);
    padding: 15px 0;
  }
}
.main-content {
  @include flexbox;
  @include flex(1, 1, auto);
  & > .container {
    display: flex;
  }
}

.email-box-wrapper {
  @include align-items(flex-start);
  @include flexbox;
  @include justify-content(flex-end);
  @include flex(0, 0, 100%);
}

.email-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 25px #00000029;
  @include border-radius(10px);
  padding: 50px;
  max-width: 470px;
}
.email-box-body {
  padding-bottom: 100px;
  p {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0px;
    color: #000000;
    font-weight: 400;
    margin-bottom: 15px;
  }
}
.email-box-header {
  h1 {
    font-size: 28px;
    font-weight: 400;
    line-height: 40px;
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 30px;
    strong {
      font-weight: 700;
      line-height: 40px;
    }
    &:before {
      content: '';
      width: 31px;
      height: 3px;
      background: #3cb896;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
.email-box-footer {
  p {
    font-size: 11px;
    line-height: 22px;
    letter-spacing: 0px;
    color: #000000;
    font-style: italic;
  }
}

.d-field {
  height: 36px;
  border: 1px solid #dfdfdf;
  @include border-radius(3px);
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.form-control {
  height: 36px;
  border: 1px solid #dfdfdf;
  @include border-radius(3px);
  padding: 0 15px;
  width: 100%;
  outline: none;
}

.submit-box {
  position: relative;
  input {
    height: 36px;
    border: 1px solid #dfdfdf;
    @include border-radius(3px);
    padding: 0 132px 0 15px;
    width: 100%;
    outline: none;
  }
  .btn-submit {
    height: 36px;
    background: #3ab795;
    border-radius: 3px;
    width: 117px;
    position: absolute;
    top: 0;
    right: 0;
    .MuiButton-label {
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 2.4px;
    }
    &:hover {
      background: #199272;
    }
  }
}

.dialog-body {
  text-align: center;
  padding: 50px;
  min-width: 420px;
}
.dialog-icon {
  padding-bottom: 25px;
  text-align: center;
}
.dialog-title {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 15px;
}

.dialog-desc {
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin: 0 auto 20px auto;
}
.dialog-field {
  margin-bottom: 20px;
}

.dialog-action {
  .btn-solid {
    height: 36px;
    background: #3ab795;
    border-radius: 3px;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    .MuiButton-label {
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 2.4px;
    }
    &:hover {
      background: #199272;
    }
  }
}

button {
  &.btn-solid {
    height: 36px;
    background: #3ab795;
    border-radius: 3px;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    .MuiButton-label {
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 2.4px;
    }
    &:hover {
      background: #199272;
    }
    &.btn-solid-auto-h {
      height: auto;
      min-height: 36px;
    }
  }
  &.btn-outline {
    height: 36px;
    background: #ffffff;
    border-radius: 3px;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    border: 1px solid #000000;
    .MuiButton-label {
      font-weight: 600;
      text-transform: uppercase;
      color: #000000;
      letter-spacing: 2.4px;
    }
    &:hover {
      background: #d84040;
      border-color: #d84040;
      .MuiButton-label {
        color: #ffffff;
      }
    }
  }
}

.v-actions {
  display: flex;
  margin: 0 -10px;
  flex-wrap: wrap;
  padding: 15px 0;
  .v-action {
    flex: 0 0 50%;
    padding: 0 10px;
  }
}

.multi-field {
  display: flex;
  margin: 0 -7px;
  align-items: center;
  justify-content: center;
}
.digit {
  padding: 0 7px;
  input {
    padding: 0 5px;
    width: 170px;
    text-align: center;
  }
}
.missed-otp {
  padding-top: 30px;
  padding-bottom: 20px;
  p {
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 20px;
  }
}
.resend-otp {
  letter-spacing: 0px;
  color: #3ab795;
  font-weight: 600;
  font-size: 12px;
}

.payment-wrapper {
  @include flexbox;
  @include justify-content(center);
  @include align-items(center);
  @include flex-wrap(wrap);
  min-height: 100vh;
}
.payment {
  display: flex;
  box-shadow: 0px 0px 50px #00000029;
  flex: 0 0 100%;
}
.payment-left,
.payment-right,
.payment-amazonleft {
  flex: 0 0 50%;
}

.payment-lefts,
.payment-right,
.payment-amazonleft {
  flex: 0 0 50%;
}
.payment-right {
  padding: 40px;
}
.payment-left {
  position: relative;
  background-image: url(../img/check.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &:before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(160, 232, 175, 0.34);
    position: absolute;
  }
}

.payment-amazonleft {
  position: relative;
  background-image: url(../img/Amazon_gift_card3.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  &:before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(160, 232, 175, 0.34);
    position: absolute;
  }
}

.payment-lefts {
  position: relative;
  background-image: url(../img/banner02.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media (max-width: 768px) {
    flex: 0 0 100%;
  }
  @media (max-width: 600px) {
    display: none;
  }
  &:before {
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(160, 232, 175, 0.34);
    position: absolute;
  }
}

.page-heading {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 15px;
}
.page-desc {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #000000;
  font-weight: 400;
  margin: 0 auto 20px auto;
}

.form-btn {
  padding-top: 15px;
  .form-save {
    height: 36px;
    background: #3ab795;
    border-radius: 3px;
    width: 100%;
    margin: 0 auto;
    .MuiButton-label {
      font-weight: 600;
      text-transform: uppercase;
      color: #ffffff;
      letter-spacing: 2.4px;
    }
    &:hover {
      background: #199272;
    }
  }
}

.tab-section {
  & > header {
    background: transparent;
    box-shadow: none;
    button {
      color: #000000;
      min-width: 0;
      margin-right: 20px;
      font-size: 12px;
    }
    .MuiTabs-indicator {
      background: #3cb896;
      height: 3px;
    }
    .MuiTabs-flexContainer {
      border-bottom: 1px solid #dfdfdf;
    }
  }
  .MuiBox-root {
    padding: 30px 0;
  }
}

.payment-options-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
  li {
    list-style-type: none;
    text-align: left;
    flex: 0 0 33.33%;
    padding: 8px;
    display: flex;
  }
}

.p-item {
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dddddd;
  background: #f7f7f7;
  padding: 15px;
  transition: all 0.3s ease;
  flex: 1 1 100%;
  cursor: pointer;
  &:hover,
  &:focus,
  &.active {
    background: #3ab795;
    color: #ffffff;
  }
}
.payment-foot {
  button.btn-solid {
    height: 36px;
    background: #3ab795;
    border-radius: 3px;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
    outline: none;
    border: 0;
    color: #ffffff;
    cursor: pointer;
    &:hover {
      background: #199272;
    }
  }
}

.t-header {
  justify-content: right !important;
}

// updated design css

.option-sep {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: #efefef 0% 0% no-repeat padding-box;
  border: 1px solid #dadada;
  border-radius: 50%;
  color: #707070;
  text-transform: uppercase;
  font-size: 10px;
  z-index: 2;
  position: relative;
}

.f-separator {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
  &:before {
    content: '';
    height: 1px;
    background: transparent
      radial-gradient(closest-side at 50% 50%, #c5c5c5 0%, #ffffff 100%) 0% 0%
      no-repeat padding-box;
    position: absolute;
    top: 13px;
    left: -50px;
    right: -50px;
    z-index: 1;
  }
}

.less-spacing {
  padding-bottom: 30px;
}

.btn-sec {
  margin-bottom: 50px;
  button.btn-solid {
    max-width: none;
  }
}

.addn-text {
  font-size: 11px;
  line-height: 22px;
  font-style: italic;
  font-weight: 400;
  a {
    text-decoration: underline !important;
  }
}

.s-header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.inner-brand {
  flex: 0 0 auto;

  @media (max-width: 600px) {
    max-width: 140px;
  }
}
.site-links {
  flex: 0 0 auto;
}
.n-links {
  display: flex;
  align-items: center;
  li {
    list-style-type: none;
    &:not(:last-child) {
      padding-right: 20px;
      margin-right: 20px;
      border-right: 1px solid #000000;
    }
  }
}
a {
  &.n-link {
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0px;
    color: #171717;
    font-weight: 400;
  }
}

.dialog-head {
  padding: 50px;
  background: #3ab795;
  border-radius: 5px 5px 75px 5px;
  text-align: center;
}

.dialog-head .dialog-desc,
.dialog-head .dialog-title {
  color: #ffffff;
}

.dialog-head .dialog-desc {
  margin-bottom: 0;
}

.multi-field input {
  background: #ececec;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
}

.otp-dialog {
  &.big-dialog {
    .MuiPaper-root {
      &.MuiDialog-paper {
        width: 100%;
        // max-width: 900px;
      }
    }
    .dialog-body {
      padding: 0;
    }
  }
  .dialog-head {
    @media (max-width: 600px) {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .dialog-icon {
    @media (max-width: 600px) {
      padding-bottom: 15px;
    }
  }
}
.dialog-inner-wrppaer {
  display: flex;
}
.dialog-inner-left,
.dialog-inner-right {
  flex: 0 0 50%;
  min-height: 300px;
}
.dialog-inner-right {
  padding: 30px;
}
h4 {
  &.owned-amount {
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    color: #000000;
    margin-bottom: 15px;
    strong {
      color: #3ab795;
    }
  }
  &.method-type-heading {
    font-size: 19px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
    line-height: 21px;
    margin-bottom: 15px;
  }
}
.css-1jh78f5-MuiPaper-root-MuiDialog-paper {
  overflow: visible !important;
}
.available-methods {
  .method {
    background-color: #13854e9d;
    border-radius: 6px;
    padding: 20px;
    position: relative;
    .dialogue-wrapper {
      rotate: 15deg;
      position: absolute;
      top: -40%;
      left: 92%;
      .dialogue {
        position: relative;
        p {
          position: absolute;
          top: 32%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff;
          font-weight: 500;
        }
      }
    }
  }
  .payment-method:hover {
    scale: 1.02;
  }
  .payment-method {
    list-style-type: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    text-align: left;
    button {
      width: 100%;
      border: none;
    }
    &:not(:last-child) {
      padding-bottom: 20px;
    }

    &:first-child {
      .method-type {
        background: #86baa1;
      }
    }
    &:nth-child(2) {
      .method-type {
        background: #6fcb9f;
      }
    }
    &:nth-child(3) {
      .method-type {
        background: #3ab795;
      }
    }
    &:last-child {
      .method-type {
        background: #86baa1;
      }
    }
  }
}
.method-type {
  display: flex;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  min-height: 88px;
  padding: 25px 40px 25px 70px;
  align-items: center;
  position: relative;
  h5 {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: uppercase;
  }
  span {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000038;
    border-radius: 50px;
    flex: 0 0 auto;
    position: absolute;
    top: 27px;
    left: 20px;
  }
  &:after {
    content: '';
    height: 12px;
    width: 13px;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: rotate(45deg);
    margin-top: -7px;
  }
}
.dialog-inner-left {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent linear-gradient(218deg, #f88d0130 0%, #3ab795 100%)
      0% 0% no-repeat padding-box;
  }
  &.method-banner {
    background-image: url(../img/method-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.charity-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.charity-selector-left,
.charity-selector-right {
  padding: 10px;
}

label.c-label {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  margin: 0;
}

.custom-dropdown select {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  height: 36px;
  padding: 0 10px;
  width: 200px;
}

.t-icon {
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1025px) {
  .container {
    max-width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .payment {
    flex-wrap: wrap;
    flex-direction: column;
    .payment-right {
      flex: 1 1 auto;
      // flex-direction: column;
      // align-items: flex-start;
      // justify-content: flex-start;
    }

    .payment-left {
      flex: 0 0 300px;
      // height: 300px;
    }
  }
  .email-box-wrapper {
    @include justify-content(center);
  }
  .dialog-inner-wrppaer {
    flex-wrap: wrap;
  }

  .dialog-inner-left,
  .dialog-inner-right {
    flex: 0 0 100%;
  }
}
@media only screen and (max-width: 767px) {
  .dialog-body {
    padding: 25px;
    min-width: 0;
  }
  .email-box-wrapper {
    @include justify-content(center);
  }
  .payment-options-list {
    li {
      flex: 0 0 100%;
    }
  }
  .dialog-inner-right {
    padding: 50px 25px;
  }
}
@import url(https://fonts.googleapis.com/css?family=Roboto);

$md-radio-checked-color: rgb(51, 122, 183);
$md-radio-border-color: rgba(0, 0, 0, 0.54);
$md-radio-size: 20px;
$md-radio-checked-size: 10px;
$md-radio-ripple-size: 15px;

@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0);
  }
}

.md-radio {
  margin: 16px 0;

  &.md-radio-inline {
    display: inline-block;
  }

  input[type='radio'] {
    display: none;
    &:checked + label:before {
      border-color: $md-radio-checked-color;
      animation: ripple 0.2s linear forwards;
    }
    &:checked + label:after {
      transform: scale(1);
    }
  }

  label {
    display: inline-block;
    min-height: $md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
    &:before,
    &:after {
      position: absolute;
      content: '';
      border-radius: 50%;
      transition: all 0.3s ease;
      transition-property: transform, border-color;
    }
    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }
    &:after {
      top: $md-radio-size / 2 - $md-radio-checked-size / 2;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2;
      width: $md-radio-checked-size;
      height: $md-radio-checked-size;
      transform: scale(0);
      background: $md-radio-checked-color;
    }
  }
}

// *************************************

// *************************************
*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  background: #f0f0f0;
  position: absolute;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  color: #333;
}

section {
  background: white;
  margin: 0 auto;
  padding: 4em;
  max-width: 800px;
  h1 {
    margin: 0 0 2em;
  }
  h3 {
    margin: 1.5em 0 0;
  }
}

.thankyou-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .payment-right {
    flex: 1 1 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
  }

  .payment {
    box-shadow: none;
    border: 1px solid #dddddd;
    background: #ffffff;
  }
  .thankyoubg {
    background-image: url(../img/thankyou-banner.png);
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 16px rgba(221, 221, 221, 0.5);
    border-radius: 0;
    border: 0;
  }

  .page-heading {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .page-desc {
    font-size: 19px;
    line-height: 36px;
    max-width: 850px;
    margin: 0 auto;
  }
}
.dash-tiles .MuiGrid-root {
  display: flex;
}

.dash-tiles div[class*='makeStyles-card-'] {
  justify-content: space-between;
}

.dash-tiles div[class*='makeStyles-cardIcon-'] {
  float: none;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
}

.dash-tiles p[class*='makeStyles-cardCategory-'] {
  padding-left: 100px;
  padding-bottom: 15px;
  min-height: 90px;
}

.t-header {
  &.search-facility {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding-right: 85px;
  }
}
.t-header {
  &.search-facility-admin {
    display: flex;
    align-items: center;
    min-height: 60px;
    padding-right: 15px;
  }
}
.search-facility {
  .t-header-left {
    width: 160px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    input {
      width: 100%;
    }
  }
  .t-header-right {
    flex: 1;
  }
}
.search-facility-admin {
  .t-header-left {
    width: 160px;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    z-index: 9;
    input {
      width: 100%;
    }
  }
  .t-header-right {
    flex: 1;
  }
}

.facilityDashboard .filterCard .search_t_head {
  padding-right: 0;
  position: absolute;
  top: 4px;
  right: 6px;
  max-width: 80px;
}

.facilityDashboard .filterCard {
  position: relative;
  padding-left: 90px !important;
}

.facilityDashboard .filterCard .filter_txt {
  position: absolute;
  top: 18px !important;
  left: 8px !important;
  text-transform: uppercase;
  font-weight: 600;
}

@media only screen and (max-width: 767px) {
  .search-facility .t-header-right {
    flex: 0 0 100%;
    margin-bottom: 10px;
  }

  .filterCard .t-header-left {
    flex: 0 0 50%;
    margin-bottom: 10px;
  }

  .t-header.search-facility {
    padding-top: 10px;
    padding-right: 10px;
  }

  .facilityDashboard .filterCard .search_t_head {
    position: static !important;
    margin-left: 10px;
  }

  .filterCard .t-header-left .react-datepicker-wrapper {
    flex: 1 1 auto;
  }

  .facilityDashboard .filterCard .filter_txt {
    position: static !important;
  }

  .facilityDashboard .filterCard {
    padding: 10px !important;
  }
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.mr-1 {
  margin-right: 0.5rem !important;
}
.ml-2 {
  margin-left: 0.75rem !important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.mt-2 {
  margin-top: 0.75rem !important;
}
.text-center {
  text-align: center !important;
}
.btn-green-outline {
  height: 40px;
  background: transparent;
  color: #3ab795;
  border: solid 1px #3ab795;
  border-radius: 3px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
}

.wrapper {
  width: 300px;
  position: fixed;

  padding: 20px;
  background-color: #fff;
  right: -280px;
  top: 60px;

  border-radius: 5px;

  transition: all 0.3s ease-in-out;

  box-shadow: 0px 0px 16px rgba(221, 221, 221, 0.5);
  .available-facilities {
    display: flex;
    flex-direction: column;
    width: 100%;

    .container {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0;
      padding: 0;
      padding-bottom: 10px;
      cursor: pointer;
      position: relative;
      margin-top: 10px;
      min-width: 100%;
      //add underling in the center of the div of 70% width useing ::after

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 70%;
        height: 1px;
        background-color: #cdcdcd;
        margin: 0 auto;

        //center horizontally
        left: 50%;
        transform: translateX(-50%);
      }
      .logo {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #3ab795;
        object-fit: cover;
      }
      .name {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
  .logout {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
    cursor: pointer;
    .icon {
      color: #fff;
      background-color: $errorColor;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px;
      border-radius: 50%;
    }
  }
}

.animate-in {
  transform: translateX(-300px);
}

.animate-out {
  transform: translateX(300px);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
