// Website theme colors
$primaryBgColor: var(--primary-bg-color, #FAFAFA);
$primaryWhiteColor: var(--primary-white-color, #ffffff);
$primaryDarkColor:var(--primary-dark-color, #374059);
$primarylightDarkColor:var(--primary-light-dark-color, #A4ACBF);
$primaryBlueColor:var(--primary-blue-color, #4E8AFF);
$lightBlueColor:var(--light-blue-color, #80D6D8);
$inactiveBlueColor:var(--inactive-blue-color, rgba(128, 214, 216, 0.25));
$inactiveLightBlueColor:var(--inactive-light-blue-color, rgba(128, 214, 216, 0.08));
$primaryHoverBlueColor:var(--primary-hover-blue-color, #3573E9);
$lightHoverBlueColor:var(--light-hover-blue-color, #65C7C9);
$primaryYelloColor:var(--light-yellow-color, #FFE185);

$textGrayColor:var(--link-color, #A4ACBF);
$textHoverColor:var(--link-hover-color, #4E8AFF);

$errorColor: var(--error-color, #EB5757);
$validColor: var(--valid-color, #47CF5C);

$primaryTextColor: var(--primary-text-color, #3F4452);